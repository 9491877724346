.choices {
  position: relative;
  overflow: hidden;
  font-size: 16px;
  outline: none;
}

.choices__inner:has(.input--text-error) {
  @apply border-danger-300;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-open {
  overflow: visible;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%2373716D'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
  background-position: right theme("spacing.2") center;
  background-repeat: no-repeat;
  background-size: theme("spacing.5");
  padding-right: theme("spacing.10");
}

.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin: 0;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow: hidden;

  @apply text-sm px-4 py-2 rounded-sm border border-neutral-400 h-10;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  @apply border-secondary-300 bg-white;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 5;
  position: absolute;
  width: 100%;
  top: calc(100% + theme("spacing.2"));
  margin-top: -1px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;

  @apply bg-white border border-neutral-400 rounded-sm shadow;
}

.is-active.choices__list--dropdown,
.is-active.choices__list[aria-expanded] {
  visibility: visible;
}

/* .is-open .choices__list--dropdown,
.is-open .choices__list[aria-expanded] {
  @apply border-secondary-100;
} */

.is-flipped .choices__list--dropdown,
.is-flipped .choices__list[aria-expanded] {
  top: auto;
  bottom: calc(100% + theme("spacing.2"));
  margin-top: 0;
  margin-bottom: -1px;
}

.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  @apply relative text-sm py-2 px-4;
}

.choices__list--dropdown .choices__item--selected,
.choices__list[aria-expanded] .choices__item--selected {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 12.75 6 6 9-13.5' /%3E%3C/svg%3E");
  background-position: right theme("spacing.2") center;
  background-repeat: no-repeat;
  background-size: theme("spacing.6");

  @apply pr-10 font-bold;
}

.choices__list--dropdown
  .choices__item--selected.choices__item--selected--no-checkmark,
.choices__list[aria-expanded]
  .choices__item--selected.choices__item--selected--no-checkmark {
  background-image: none;
}

@media (width >= 640px) {
  .choices__list--dropdown .choices__item--selectable::after,
  .choices__list[aria-expanded] .choices__item--selectable::after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  @apply bg-neutral-200;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted::after,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus,
.choices__input:focus,
.choices__input:focus-visible,
.choices__list:focus-visible {
  outline: 0;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}

.choices__input::-webkit-search-cancel-button,
.choices__input::-webkit-search-decoration,
.choices__input::-webkit-search-results-button,
.choices__input::-webkit-search-results-decoration {
  display: none;
}

.choices__input::-ms-clear,
.choices__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.choices__placeholder {
  color: theme("colors.neutral.600");
}

/* debug purpose: keep select open for easy inspection */

/*
.choices[data-type*="select-one"] { overflow: visible !important; }
.choices__list--dropdown, .choices__list[aria-expanded] { visibility: visible !important; }
*/

/* end debug purpose */
