/* The following declarations are all for supporting safari 14+15 Dialog polyfills */

/* This is called Dialog and not Modal since it is specifically concerned with the HTMLDialogElement */
dialog:not([open]),
dialog-polyfill:not([open]) {
  @apply hidden;
}

dialog-polyfill.as-modal {
  @apply fixed inset-x-0 inset-y-0 z-[1000] bg-neutral-700/20 flex items-center justify-center;
}

dialog.dialog-out,
dialog-polyfill.dialog-out > div {
  @apply animate-dialog-out;
}

dialog.dialog-in,
dialog-polyfill.dialog-in > div {
  @apply animate-dialog-in;
}

dialog-polyfill.dialog-in {
  @apply animate-fade-in;
}

dialog-polyfill.dialog-out {
  @apply animate-fade-out;
}

/* End Dialog polyfill support */
