@layer components {
  .modal {
    --input-bg: theme("colors.white");

    @apply flex flex-col gap-6 text-sm bg-white;
  }

  .modal__content {
    @apply overflow-y-auto px-6 pt-1 pb-1 sm:px-8 scrollbar-thin max-h-[50vh];
  }

  .modal__footer {
    @apply flex flex-col gap-6 justify-between px-6 pb-6 sm:flex-row sm:items-center sm:px-8 sm:pb-8;
  }

  /**
   * LEGACY Modal styles
   */

  /* Modals connectors */

  ._modal_width {
    width: 100%;
  }

  @screen sm {
    ._modal_width {
      width: 536px;
    }

    ._modal_width[data-size="large"] {
      width: 656px;
    }
  }

  /* Center modal based on left nav column showing or not. */
  ._client-modal-position {
    left: calc(var(--side-nav) / 2);

    @apply relative;
  }

  ._modal_left_column {
    width: 400px;
  }

  @media (width <=1200px) {
    ._client-modal-position {
      left: auto;
    }
  }
}
