@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./coffee-bean/navbar.css";
@import "./coffee-bean/webfont.css";
@import "./coffee-bean/form.css";
@import "./coffee-bean/button.css";
@import "./coffee-bean/custom-select.base.css";
@import "./coffee-bean/link.css";
@import "./coffee-bean/dialog.css";
@import "./coffee-bean/modal.css";
@import "./coffee-bean/toast.css";
@import "./coffee-bean/table.css";
@import "./coffee-bean/pill.css";
@import "tippy.js/dist/tippy.css";

@layer base {
  :root {
    --side-nav: 248px;
  }

  body {
    font-feature-settings:
      "calt" 1,
      "rvrn" 1,
      "kern" 1,
      "rclt" 1,
      "ss01" 1,
      "ss02" 1,
      "ss03" 1,
      "ss07" 1,
      "ss08" 1,
      "rlig" 1;
  }

  main {
    view-transition-name: main;
  }

  footer {
    view-transition-name: footer;
  }

  /* html */
  .no-sidenav {
    --side-nav: 0;
  }

  label,
  a,
  button,
  input[type="submit"],
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  [role="button"],
  .choices,
  select[class],
  input ~ label {
    @apply transition-all ease-out rounded-sm outline-none ring-offset-transparent ring-offset-4 ring-transparent ring-4;
  }

  label:focus-visible,
  label:focus-within,
  a:focus-visible,
  button:focus-visible,
  .focus-visible,
  input[type="submit"]:focus-visible,
  input[type="text"]:focus-visible,
  input[type="email"]:focus-visible,
  input[type="password"]:focus-visible,
  input[type="tel"]:focus-visible,
  select[class]:focus-visible,
  [role="button"]:focus-visible,
  .choices.is-focused,
  .choices.is-open,
  input:checked:focus-visible ~ label {
    @apply ease-in outline-none ring-offset-0 ring-secondary-100;
  }

  /* removes lightblue bg when autofilled. Yes box-shadow to style a background-color ಥ‿ಥ  */
  /* stylelint-disable */
  input:-webkit-autofill {
    -webkit-box-shadow:
      var(--tw-ring-offset-shadow),
      var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000),
      0 0 0 30px theme("colors.white") inset !important;
  }
  /* stylelint-enable */
}

@layer components {
  .overflow-seperator {
    @apply absolute inset-0 -z-[1];
  }

  .overflow-seperator::before {
    content: "";
    margin-left: calc((100% - (100vw - var(--scroll-width, 0))) / 2);
    width: calc(100vw - var(--scroll-width, 0));

    @apply absolute inset-0 block bg-neutral-100 border-t border-neutral-300;
  }

  @screen xl {
    .overflow-seperator::before {
      width: calc((100vw - var(--scroll-width)) - var(--side-nav, 0));
      margin-left: calc(
        ((100% + var(--side-nav, 0)) - (100vw - var(--scroll-width))) / 2
      );
    }
  }
}

@layer utilities {
  .scrollbar-thin {
    scrollbar-width: thin;
    scroll-padding-right: 2rem;
  }

  .scrollbar-thin::-webkit-scrollbar {
    width: 6px;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar-thin::-webkit-scrollbar-track:hover {
    background: theme("colors.neutral.200");
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: theme("colors.neutral.400");
    border-radius: 6px;
    border: 3px solid transparent;
  }

  ._debug,
  ._debug * {
    @apply outline outline-red-100 -outline-offset-1 bg-red-200/10;
  }
}

/* Optico overrides 💀 */
/* stylelint-disable-next-line */
#optico_domain_url {
  display: none !important;
}

/* stylelint-disable-next-line */
#optico_call_me_back_form_section {
  padding: 30px !important;
}

/* intlTelInput "fixes"  */
.iti {
  width: 100%;
}

.iti--fullscreen-popup > .iti__dropdown-content {
  margin: auto !important;
  width: 600px !important;
  max-width: 100% !important;
  height: clamp(50dvh, 18rem, 75dvh) !important;
}

.iti__search-input {
  @apply focus-visible:outline-neutral-300;
}

.iti__selected-flag {
  @apply focus-visible:outline-none;
}

/* tooltip : https://atomiks.github.io/tippyjs/v6/themes/ */
.tippy-box {
  background-color: #131010;
  color: #fff;
  border-radius: 2px;
}

.tippy-arrow {
  color: #131010;
}
