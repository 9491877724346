/**
BEM class name structure
._table__wrapper
  ._table
    ._table__thead
      ._table__row
        ._table__cell
          ._table__link
          ._table__action
*/

@layer components {
  ._table__wrapper {
    background-color: var(--bg-color, #fff);

    @apply border border-neutral-300 rounded-sm;
  }

  ._table {
    @apply w-full text-sm text-primary-300 border-collapse table-auto;
  }

  ._table--hoverable tbody tr {
    @apply transition-colors hover:bg-neutral-200;
  }

  ._table__thead {
    @apply text-neutral-600 font-bold text-left text-sm hidden sm:table-header-group;
  }

  ._table__row {
    @apply relative;
  }

  ._table__cell {
    @apply px-3 py-2 border-b border-neutral-200 align-middle overflow-hidden sm:overflow-visible text-ellipsis whitespace-nowrap;
    @apply sm:px-4 sm:py-3;
  }

  ._table__cell--truncate {
    @apply w-full max-w-0 truncate;
  }

  tbody ._table__row:last-child ._table__cell {
    @apply border-b-0;
  }

  ._table__link {
    /**
      Tricks where the before pseudo element extends the click zone to whole <tr>.
      This allow to not use javacript to make the whole row actionnable
    */
    @apply before:content-[''] before:absolute before:inset-0;
  }

  /* TODO: refactor/merge these with new notation  */
  ._thead_table_row {
    @apply h-12 border border-neutral-200;
  }

  ._table_wrapper {
    @apply bg-white border border-neutral-300 rounded mt-4 sm:mt-6;
  }

  ._table_row {
    @apply h-12 transition-colors border border-neutral-200 hover:bg-neutral-200;
  }

  ._table_cell {
    @apply px-4;
  }

  ._table_progress {
    border: revert;

    @apply h-1 rounded-full bg-neutral-400 w-[56px];
  }

  ._table_progress::-webkit-progress-bar {
    @apply bg-neutral-400 rounded-md overflow-hidden;
  }

  ._table_progress[value]::-webkit-progress-bar {
    @apply bg-neutral-400;
  }

  ._table_progress[value]::-webkit-progress-value {
    @apply bg-primary-300 rounded-md transition-all duration-100;
  }

  ._table_progress[value]::-moz-progress-bar {
    @apply bg-primary-300 rounded-md transition-all duration-100;
  }
}
