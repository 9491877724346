@layer components {
  .btn {
    @apply relative flex gap-x-2 items-center justify-center cursor-pointer font-medium border-0 no-underline outline-none transition rounded-sm w-full bg-transparent;
    @apply text-base py-3 px-6;
    @apply focus-visible:outline-transparent;
    @apply disabled:bg-neutral-200 disabled:text-neutral-400 disabled:hover:bg-neutral-200 disabled:hover:text-neutral-400;
    @apply sm:text-sm sm:leading-6 sm:py-2 sm:px-4;
  }

  .btn--primary {
    @apply bg-primary-300 hover:bg-primary-200 text-white;
  }

  .btn--secondary {
    @apply bg-secondary-200 hover:bg-secondary-300 text-neutral-100 hover:text-neutral-200;
  }

  .btn--link {
    @apply text-neutral-600 hover:text-neutral-700;
  }

  .btn--primary-danger {
    --focus-active: theme(colors.danger.300);

    @apply bg-danger-300 hover:bg-danger-300 text-white;
  }

  .btn--secondary-danger {
    --focus-active: theme(colors.danger.300);

    @apply bg-danger-200 hover:bg-danger-300 text-danger-300 hover:text-danger-300;
  }

  .btn--outline {
    @apply border border-primary-300 hover:bg-neutral-200;
  }

  .btn--outline-light {
    @apply border border-neutral-400 hover:bg-neutral-200;
  }

  .btn--tabs {
    @apply px-3 py-2 rounded-lg font-normal;
  }

  .btn--rounded {
    @apply rounded-full;
  }

  .btn--small {
    @apply h-8 text-sm p-2 sm:py-1 sm:px-3;
  }

  .btn--square {
    @apply w-10 aspect-square p-0 font-normal;
  }

  .btn--active {
    --bg-color: theme(colors.neutral.100);

    @apply font-bold cursor-default hover:bg-white;
    @apply border-gradient-to-t from-pink-200 to-energy-200;
  }

  /**
    * btn--disabled is used for buttons that are disabled but still need to be
    * clickable for accessibility reasons.
    * e.g. visually disabled forms submit.
    */
  .btn--disabled {
    @apply bg-neutral-200 text-neutral-400 hover:bg-neutral-300;
  }

  /**
    * btn--inert is used for elements that are not clickable
    * but looks like a button.
    * e.g. pagination arrows when there is no next/previous page.
    */
  .btn--inert {
    @apply bg-neutral-100 border border-neutral-200 text-neutral-400 cursor-default;
  }
}
