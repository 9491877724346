@layer components {
  /* navbar component base definition: display: vertical */
  .navbar {
    @apply hidden xl:overflow-y-auto xl:fixed top-0 xl:flex-col xl:py-12 xl:px-6 h-full xl:flex shrink-0 bg-neutral-200 xl:w-sidenav;
  }

  .navbar__brand {
    @apply mb-12;
  }

  .navbar__nav {
    @apply flex flex-col text-sm text-primary-300 flex-grow gap-1.5 mb-8 bg-neutral-200;
  }

  .navbar__nav-item:is([href]) {
    @apply w-full inline-flex items-center gap-2 p-2 hover:bg-neutral-300 whitespace-nowrap rounded-[4px];
  }

  .navbar__nav-item--current:is([href]) {
    @apply text-primary-300 bg-neutral-300 hover:bg-neutral-400;
  }

  .navbar__dropdown {
    @apply p-2 flex flex-col gap-2 bg-neutral-200;
  }

  .navbar__nav--secondary {
    @apply gap-4 text-xs grow-0 mb-0;

    & .navbar__nav-item:is([href]) {
      @apply p-1 hover:text-neutral-600 hover:bg-inherit;
    }
  }

  /* navbar component modifier: display horizontal */
  .navbar--horizontal {
    @apply relative flex pr-4 pl-6 w-full h-12 sm:px-8 sm:h-16 xl:hidden items-center;

    & .navbar__brand {
      @apply mb-0;
    }

    & .navbar__nav {
      @apply flex-row mb-0 items-center w-auto gap-x-4;
    }

    & .navbar__nav--primary {
      @apply ml-auto grow-0;
    }
  }
}
