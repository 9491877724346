@layer components {
  .toast {
    @apply bg-neutral-700 flex p-4 w-full pointer-events-auto sm:w-auto sm:rounded-sm sm:shadow-xl text-neutral-100;
  }

  .toast--success {
    @apply bg-success-300;
  }

  .toast--error {
    @apply bg-danger-300 text-white;
  }

  .toast--warning {
    @apply bg-warning-300;
  }
}
