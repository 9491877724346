@layer components {
  .link {
    @apply text-secondary-300 hover:text-secondary-200 focus-visible:rounded-sm;
    @apply inline-flex gap-2 items-center text-sm transition outline-none rounded-none;
    @apply aria-disabled:text-secondary-200 aria-disabled:cursor-not-allowed aria-disabled:hover:text-neutral-400;
  }

  .link--danger {
    --focus-active: theme(colors.danger.300);

    @apply text-danger-300 hover:text-danger-200;
  }

  .link--neutral {
    @apply text-neutral-500 hover:text-neutral-600;
  }

  .link--light {
    @apply text-white hover:text-neutral-300;
  }

  .link--dark {
    @apply text-primary-300 border-b border-b-current hover:bg-neutral-200 hover:text-primary-300;
  }
}
