@font-face {
  font-family: ABCRepro;
  src:
    local("ABC Repro"),
    url("https://cdn.silvr.co/fonts/ABCReproBasic-Regular.woff2")
      format("woff2"),
    url("https://cdn.silvr.co/fonts/ABCReproBasic-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: ABCRepro;
  src:
    local("ABC Repro"),
    url("https://cdn.silvr.co/fonts/ABCReproBasic-Medium.woff2") format("woff2"),
    url("https://cdn.silvr.co/fonts/ABCReproBasic-Medium.woff") format("woff");
  font-weight: 500 700;
  font-display: swap;
}
