@layer components {
  input::-webkit-credentials-auto-fill-button {
    position: absolute !important;
    right: 40px !important;
  }

  ._form_input_v2 {
    @apply block w-full h-10 px-4 py-2 text-sm font-normal transition duration-150 ease-in-out border rounded-sm appearance-none border-neutral-300 placeholder:text-neutral-500 text-neutral-700 sm:text-sm sm:leading-5 focus:text-neutral-700 focus:ring-0;
  }

  ._form_input_error_v2 {
    @apply block w-full h-10 px-4 py-2 text-sm font-normal transition duration-150 ease-in-out border rounded-sm appearance-none border-danger-300 placeholder:text-neutral-500 focus:outline-none sm:text-sm sm:leading-5 focus:text-neutral-700 focus:ring-0 focus:border-danger-300;
  }

  ._form_input_disabled_v2 {
    @apply block w-full px-4 py-2 text-sm font-normal transition duration-150 ease-in-out border rounded-sm appearance-none bg-neutral-100 border-neutral-300 placeholder:text-neutral-400 text-neutral-500 focus:outline-none sm:text-sm sm:leading-5;
  }

  ._date_picker {
    @apply flex justify-between items-center gap-4 h-10 bg-neutral-100 min-w-[11rem] px-4 py-2 rounded-sm text-sm font-normal text-neutral-700 focus:outline-none transition duration-150 ease-in-out sm:text-sm focus:text-neutral-700 focus:ring-0;
  }

  ._checkbox_squared_V2 {
    @apply inline-flex items-center justify-between w-5 h-5 px-1 py-1 text-base font-medium bg-white border rounded-sm text-neutral-400 border-neutral-300 focus:outline-none;
  }

  ._toggle_blue_v2 {
    @apply relative inline-flex items-center shrink-0 h-8 w-14 border-2 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none px-0.5;
  }

  ._toggle_blue_v2__switch {
    @apply inline-block w-6 h-6 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full ring-0;
  }

  ._user_menu_mobile {
    @apply w-full p-6 bg-white rounded-t-sm rounded-b-none h-80 ring-0 ring-primary-300 ring-opacity-5 focus:outline-none;
  }

  ._menu_row {
    @apply inline-flex items-center justify-start px-4 py-2;
  }

  ._menu_row_full {
    @apply inline-flex items-center justify-start w-full px-4 py-2 my-1 border rounded-lg outline-none hover:bg-neutral-100 focus:bg-neutral-100;
  }

  ._menu_item {
    @apply block px-2 text-sm outline-none;
  }

  /* square checkbox login */

  .appearance--input-text {
    appearance: textfield;
  }

  .appearance--input-text::-webkit-inner-spin-button,
  .appearance--input-text::-webkit-outer-spin-button {
    appearance: none;
  }

  .input-range {
    --track-color: theme("colors.primary.200");
    --track-height: 2px;
    --track-border-radius: var(--track-height);
    --thumb-bg-color: theme("colors.white");
    --thumb-border-color: theme("colors.neutral.300");
    --thumb-border-width: theme("borderWidth.DEFAULT");
    --thumb-height: theme("width.6");
    --thumb-width: theme("width.6");
    --thumb-border-radius: var(--thumb-width);

    appearance: none;
    background: transparent;
    cursor: pointer;
    padding-block: theme("space.1");
  }

  /* Removes default focus */
  .input-range:focus {
    outline: none;
  }

  /******** Chrome, Safari, Opera and Edge Chromium styles ********/

  /* slider track */
  .input-range::-webkit-slider-runnable-track {
    background-image: linear-gradient(
      to right,
      theme("colors.primary.200") 0%,
      theme("colors.primary.200") var(--track-split-width),
      theme("colors.neutral.200") var(--track-split-width),
      theme("colors.neutral.200") 100%
    );
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: var(--track-border-radius);
    height: var(--track-height);
  }

  /* slider thumb */
  .input-range::-webkit-slider-thumb {
    position: relative; /* Override default look */
    appearance: none;
    background-color: var(--thumb-bg-color);
    border-radius: var(--thumb-border-radius);
    border: var(--thumb-border-width) solid var(--thumb-border-color);
    height: var(--thumb-height);
    width: var(--thumb-height);
    top: calc(((var(--thumb-height) / 2) - var(--track-height)) * -1);
    transition: all 0.1s ease-in-out;

    @apply border-gradient-to-t from-neutral-400 to-neutral-400;
  }

  .input-range:active::-webkit-slider-thumb,
  .input-range:focus-visible::-webkit-slider-thumb {
    --thumb-height: theme("width.7");

    @apply from-pink-200 to-energy-200;
  }

  /*********** Firefox styles ***********/

  /* yes it's a copy paste from the rules attached to chrome and others, but it needs to be declared alone in order to work properly */

  /* slider track */
  .input-range::-moz-range-track {
    background-image: linear-gradient(
      to right,
      theme("colors.primary.200") 0%,
      theme("colors.primary.200") var(--track-split-width),
      theme("colors.neutral.200") var(--track-split-width),
      theme("colors.neutral.200") 100%
    );
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: var(--track-border-radius);
    height: var(--track-height);
  }

  .input-range::-moz-range-thumb {
    position: relative; /* Override default look */
    appearance: none;
    background-color: var(--thumb-bg-color);
    border-radius: var(--thumb-border-radius);
    border: var(--thumb-border-width) solid var(--thumb-border-color);
    height: var(--thumb-height);
    width: var(--thumb-height);
    top: calc(((var(--thumb-height) / 2) - var(--track-height)) * -1);
    transition: all 0.1s ease-in-out;

    @apply border-gradient-to-t from-primary-300 to-primary-300;
  }

  .input-range:active::-moz-range-thumb,
  .input-range:focus-visible::-moz-range-thumb {
    --thumb-height: theme("width.7");

    @apply from-pink-200 to-energy-200;
  }
}

@layer components {
  .input {
    /* BEM logical placeholder */
  }

  /* any input icon */
  .input__icon {
    @apply text-neutral-500;
  }

  .input__icon--error {
    @apply text-danger-300;
  }

  .input__error {
    @apply text-danger-300 text-sm;
  }

  /* End any input icon */

  /* input_field */
  .input__label {
    @apply text-sm font-medium;
  }

  .input--text {
    @apply !appearance-auto text-primary-300 border text-sm py-2 px-4 w-full !bg-[color:--input-bg] border-neutral-400 rounded-sm disabled:border-secondary-300 disabled:!bg-neutral-200 disabled:text-neutral-700 disabled:cursor-not-allowed  focus-visible:!bg-white focus-visible:border-secondary-300 placeholder:text-neutral-600;

    &:not(div) {
      @apply read-only:border-neutral-300 read-only:!bg-neutral-100 read-only:text-neutral-700 read-only:cursor-not-allowed read-only:focus-visible:border-neutral-300 read-only:focus-visible:ring-0;
    }
  }

  .input--text-with-icon {
    @apply pl-11 pr-4;
  }

  /* input select */
  .input--select {
    @apply appearance-none h-10 text-primary-300 border text-sm py-2 px-4 w-full bg-neutral-100 border-neutral-400 rounded-sm disabled:border-secondary-300 disabled:bg-neutral-200 disabled:text-neutral-700 disabled:cursor-not-allowed focus-visible:bg-white focus-visible:border-secondary-300  placeholder:text-neutral-600;
  }

  .input--text-error {
    @apply border-danger-300 ring-danger-200 focus-visible:border-danger-300 focus-visible:ring-danger-200;
  }

  /* End input_field */

  /* checkbox card */
  .input--checkbox-card {
    @apply relative w-full min-w-0;
  }

  .border-gradient {
    background-image: linear-gradient(
        var(--bg-color, theme("colors.neutral.100")),
        var(--bg-color, theme("colors.neutral.100"))
      ),
      linear-gradient(
        var(--border-gradient-dir, to right),
        var(--tw-gradient-stops)
      );
    background-clip: padding-box, border-box;
    background-origin: border-box;

    @apply border border-transparent;
  }

  .border-gradient-to-r {
    --border-gradient-dir: to right;

    @apply border-gradient;
  }

  .border-gradient-to-t {
    --border-gradient-dir: to top;

    @apply border-gradient;
  }

  .input--checkbox {
    --chkbg: theme(colors.primary.300);
    --chkfg: theme(colors.white);

    @apply border-neutral-300 rounded-sm size-4 cursor-pointer appearance-none border;

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      @apply outline-neutral-400 outline outline-2 outline-offset-2;
    }

    &:checked,
    &[aria-checked="true"] {
      @apply bg-no-repeat;

      animation: checkmark var(--animation-input, 0.2s) ease-out;
      background-color: var(--chkbg);
      background-image: linear-gradient(
          -45deg,
          transparent 65%,
          var(--chkbg) 65.99%
        ),
        linear-gradient(45deg, transparent 75%, var(--chkbg) 75.99%),
        linear-gradient(-45deg, var(--chkbg) 40%, transparent 40.99%),
        linear-gradient(
          45deg,
          var(--chkbg) 30%,
          var(--chkfg) 30.99%,
          var(--chkfg) 40%,
          transparent 40.99%
        ),
        linear-gradient(-45deg, var(--chkfg) 50%, var(--chkbg) 50.99%);
    }
  }

  .input--checkbox--success {
    --chkfg: theme(colors.white);
    --chkbg: theme(colors.success.300);
  }

  .input--checkbox-card .input__label {
    @apply font-normal  hover:[--bg-color:theme("colors.neutral.200")] flex gap-2 justify-start items-center py-2 px-3 h-10 text-sm rounded-sm border ring-offset-4 transition ease-out cursor-pointer select-none bg-[color:--input-bg] border-neutral-400 hover:bg-neutral-200;
  }

  .input--checkbox-card .peer:checked ~ .input__label {
    @apply border-gradient-to-t from-pink-200 to-energy-200;
  }

  .input--checkbox-card .input__check-icon {
    @apply absolute bottom-0 right-4 top-1/2 w-6 h-6 opacity-0 -translate-y-1/2 pointer-events-none text-primary-300;
  }

  /* end checkbox card */

  /* Input phone number */
  .input--phone {
    @apply block w-full font-normal transition duration-150 ease-in-out appearance-none sm:text-sm placeholder:text-neutral-600;
  }

  .input--phone-error {
    @apply input--phone border-danger-200 focus:outline-none sm:text-sm focus:ring-0 focus:border-danger-300;
  }
}
