@layer components {
  .pill {
    @apply inline-flex items-center px-3 py-1 text-xs bg-neutral-300 text-neutral-700 font-bold whitespace-nowrap rounded-full;
  }

  .pill--success {
    @apply bg-success-100 text-success-300;
  }

  .pill--danger {
    @apply bg-danger-100 text-danger-300;
  }

  .pill--outline {
    @apply border border-neutral-600 bg-transparent text-primary-300;
  }

  .pill--gradient {
    --bg-color: theme(colors.white);

    @apply border-gradient-to-t from-pink-200 to-energy-200;
  }
}
